import {
  StatsV2,
  EContainerVariant,
  Container,
  EStatsAlign,
  EStatsColor,
  EStatsNumberPosition,
  ESizeSettings,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TStatsV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'stats-v2' }
>;

const StatsV2Connected: React.FC<TStatsV2ConnectedProps> = ({
  stats = [],
  sizeSettings,
  align,
  color,
  numberPosition,
}) => (
  <Container variant={EContainerVariant.Full}>
    <StatsV2
      stats={stats}
      align={align as EStatsAlign}
      color={color as EStatsColor}
      size={sizeSettings as ESizeSettings}
      position={numberPosition as EStatsNumberPosition}
    />
  </Container>
);

export default StatsV2Connected;
