export enum EStatsColor {
  Black = 'black',
  White = 'white',
}

export enum ESizeSettings {
  Fixed = 'fixed',
  Dynamic = 'dynamic',
}

export enum EStatsAlign {
  Center = 'center',
  Left = 'left',
}

export enum EStatsNumberPosition {
  NumbersAbove = 'numbersAbove',
  NumbersUnder = 'numbersUnder',
}
